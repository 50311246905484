import { S3Destination, ZmClientConstructor } from "@luma-team/shared";
import axios, { CancelToken } from "axios";
import { getImageDimensions, readFileAsDataUrl } from "./file";

const MAX_IMAGE_SIZE = 50_000_000; // 50 megabytes

// Cloudflare supports images up to 10,000 x 10,000 pixels.
// https://support.cloudflare.com/hc/en-us/articles/4412024022029-Troubleshoot-Image-Resizing-problems#h_D32nleATv1wWhhQ84F0fX
const MAX_IMAGE_DIMENSION = 10_000;

export class FileTooBigError extends Error {
  message = "The file is too big. Please choose a file that's under 50 MB.";
}

export class ImageDimensionTooBigError extends Error {
  message =
    "The image is too big. Please choose an image that has width and height smaller than 10,000 pixels.";
}

export const uploadFileToS3 = async ({
  file,
  destination,
  type = "image",
  onUploadProgress,
  cancelToken,
  ZmClient,
}: {
  file: File;
  type?: "image" | "video" | "file" | "json";
  destination: S3Destination;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  cancelToken?: CancelToken;
  ZmClient: ZmClientConstructor;
}): Promise<{ file_url: string }> => {
  if (type === "image" && file.size > MAX_IMAGE_SIZE) {
    throw new FileTooBigError();
  }

  if (type === "image") {
    const imageDataUrl = await readFileAsDataUrl(file);
    const image = await getImageDimensions({ url: imageDataUrl });
    if (
      image.width > MAX_IMAGE_DIMENSION ||
      image.height > MAX_IMAGE_DIMENSION
    ) {
      throw new ImageDimensionTooBigError();
    }
  }

  const { upload_url, file_url } = await ZmClient.post(
    "/cdn/create-presigned-url",
    {
      content_type: file.type,
      destination,
    }
  );

  await axios.put(upload_url, file, {
    headers: {
      "Content-Type": file.type,
    },
    cancelToken,
    onUploadProgress,
  });

  return { file_url };
};
