import {
  getImageUrl,
  S3Destination,
  ZmClientConstructor,
} from "@luma-team/shared";
import UploadIcon from "@lux/icons/feather/arrow-up.svg";
import PhotoIcon from "@lux/icons/sf/Photo.svg";
import XIcon from "@lux/icons/feather/x.svg";
import classNames from "classnames";
import { getIn, useFormikContext } from "formik";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFileToS3 } from "../utils/upload-file-to-s3";
import { ACCEPT_IMAGE_PROP } from "./DropZone";
import { LuxButton } from "./LuxButton";
import { LuxInputLabel } from "./LuxInputLabel";
import { Spinner } from "./Spinner";

type SimpleDropZoneProps = {
  label: string;
  size: number;
  image: string | null;
  shape?: "square" | "circle" | "rectangle" | "wide-rectangle";
  contain?: boolean;
  destination: S3Destination;
  ZmClient: ZmClientConstructor;
  onImageChange: (args: { image: string | null }) => void;
  className?: string;
};

const getImageSizeForShape = ({
  shape,
  size,
}: {
  shape: Exclude<SimpleDropZoneProps["shape"], undefined>;
  size: number;
}): { width: number; height: number } => {
  const height = size;
  switch (shape) {
    case "square":
    case "circle":
      return { width: height, height };
    case "rectangle":
      return { width: height * 2, height: height };
    case "wide-rectangle":
      return { width: height * 5, height: height };
  }
};

export const SimpleDropZone = ({
  label,
  size,
  image,
  shape = "square",
  destination,
  ZmClient,
  onImageChange,
  className,
  contain,
}: SimpleDropZoneProps) => {
  const [uploading, setUploading] = useState<boolean>(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ACCEPT_IMAGE_PROP,
    multiple: false,
    onDrop: async (files) => {
      setUploading(true);
      try {
        const [file] = files;
        const { file_url } = await uploadFileToS3({
          file,
          destination,
          ZmClient,
        });
        onImageChange({ image: file_url });
      } finally {
        setUploading(false);
      }
    },
    noKeyboard: true,
  });

  const onRemoveImage = () => {
    onImageChange({ image: null });
  };

  return (
    <div className={className}>
      <LuxInputLabel text={label} />
      <div
        className="simple-drop-zone-container"
        style={{ "--size": size + "px" } as React.CSSProperties}
      >
        <div
          {...getRootProps()}
          className={classNames("drop-zone", {
            active: isDragActive,
            "has-image": Boolean(image),
            square: shape === "square",
            rectangle: shape === "rectangle",
            "wide-rectangle": shape === "wide-rectangle",
            circle: shape === "circle",
            contain,
          })}
          style={{
            backgroundImage: image
              ? `url(${getImageUrl({
                  url: image,
                  ...getImageSizeForShape({ shape, size }),
                  fit: contain ? "scale-down" : undefined,
                })})`
              : "none",
          }}
        >
          <input {...getInputProps()} />

          <div className="image-icon flex-center-center">
            <PhotoIcon />
          </div>

          <div className={classNames("icon", { spinner: uploading })}>
            {uploading ? <Spinner /> : <UploadIcon />}
          </div>
        </div>

        {image && (
          <div className="remove-button">
            <LuxButton
              label="Remove Image"
              icon={<XIcon />}
              iconPlacement="icon-only"
              rounded
              size="small"
              color="light"
              onClick={onRemoveImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export function SimpleDropZoneField<Values extends Record<string, unknown>>({
  name,
  ...props
}: {
  name: keyof Values & string;
} & Omit<SimpleDropZoneProps, "image" | "onImageChange">) {
  const { values, setFieldValue } = useFormikContext<Values>();

  const value = getIn(values, name as string);

  return (
    <SimpleDropZone
      {...props}
      image={value}
      onImageChange={({ image }) => setFieldValue(name, image)}
    />
  );
}
