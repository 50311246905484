import { DateTime } from "luxon";

export const readFileAsDataUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      resolve(e.target!.result as string);
    };
    reader.onerror = (event) => {
      reject(event.target!.error);
    };
    reader.readAsDataURL(file);
  });
};

type ImageDimensions = { width: number; height: number };
export const getImageDimensions = async ({
  url,
  timeout = 3000,
}: {
  url: string;
  timeout?: number | null;
}): Promise<ImageDimensions> => {
  return Promise.race([
    new Promise<ImageDimensions>((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve({ width: image.naturalWidth, height: image.naturalHeight });
      };
      image.onerror = (event) => {
        if (typeof event === "string") {
          reject(new Error(event));
        } else {
          reject(new Error("Failed to load the image."));
        }
      };
      image.src = url;
    }),
    new Promise<ImageDimensions>((_resolve, reject) => {
      if (timeout) {
        setTimeout(
          () => reject(new Error("Failed to get image dimensions.")),
          timeout
        );
      }
    }),
  ]);
};

export const downloadFile = ({
  contents,
  mimeType,
  filePrefix,
  fileSuffix,
}: {
  contents: string | number[];
  mimeType: string;
  filePrefix: string;
  fileSuffix: "csv" | "ics" | "pkpass" | "md";
}) => {
  const blob = new Blob(
    [typeof contents === "string" ? contents : new Uint8Array(contents)],
    { type: mimeType }
  );
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;

  const dateString = DateTime.now().toFormat("yyyy-MM-dd-HH-mm-ss");

  a.download = `${filePrefix} - ${dateString}.${fileSuffix}`;
  a.click();
};
